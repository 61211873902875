<template>
  <div>
    <Row :gutter="8" class="p-b-5">
        <i-col span="12">
          <i-input size="small" placeholder="关键字：通知标题模糊查询" v-model="query.keyword"></i-input>
        </i-col>
        <i-col span="6">
          <i-button size="small" icon="ios-search" type="primary" @click="handleSearch">搜索</i-button>
          <i-button @click="CreateNotice" class="m-l-5" type="success"  size="small">创建新通知</i-button>
        </i-col>
    </Row>

    <Table size="small" :columns="noticeColumns" :data="noticeData" stripe></Table>
    <div class="paging_style">
      <Page size="small" :total="total" show-total show-elevator  :current="query.pageNumber" :page-size="query.pageSize" @on-change="handleChangePage"></Page>
    </div>
  </div>
</template>

<script>
import { getNoticePage } from '@/api/sys/notice'

export default {
  data () {
    return {
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: ''
      },

      noticeColumns: [
        { title: '标题', key: 'title' },
        { title: '创建者', key: 'createUserName' },
        { title: '创建时间', key: 'createTime' },
        { title: '通知内容', key: 'content', ellipsis: true },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.showDetails(params)
                  }
                }
              }, '详情'),

              h('a', {
                style: {
                  'margin-left': '5px',
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.$store.commit('set_approvalCreate_detailId', params.row.id)
                  }
                }
              }, '编辑重发')
            ])
          }
        }
      ],
      noticeData: []
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getNoticePage(this.query).then(res => {
        this.noticeData = res.list
        this.total = res.totalRow
      })
    },
    handleChangePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    CreateNotice () {
      this.$store.commit('set_approvalCreate_detailId', null)
    },
    showDetails (params) {
      this.$store.commit('set_approvalCreate_pageType', 'detail')
      this.$store.commit('set_approvalCreate_detailId', params.row.id)
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.approvalCreate.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
      this.handleSearch()
    }
  }
}
</script>
